/* eslint-disable */
import httpRequest from '../../../util/Request'

// 获取侧边柜管理的机器列表
export function apiGetManageMachineList (userId) {
  return httpRequest({
    url: '/api/index/zyMachinelist',
    method: 'post',
    data: { user_id: userId }
  })
}

// 获取侧边柜某台机器的详细列表
export function apiGetMachineDetail (machineId) {
  return httpRequest({
    url: '/api/index/cbglist',
    method: 'post',
    data: { machine_id: machineId }
  })
}

// 获取侧边柜某台机器的详细列表
export function apiGetMachineGoodsRenew (machineId, goods) {
  return httpRequest({
    url: '/api/index/cbgupdate',
    method: 'post',
    data: {
      machine_id: machineId,
      goods
    }
  })
}
