<template>
  <div class="container">
    <div class="wrap">
      <ul class="list">
        <li class="list-item" v-for="item in data.list" :key='item' @click="toRouter(item.machine_code)">
          {{ item.machine_name }}
        </li>
      </ul>
    </div>
    <div class="footer">
      <a-button class="btn" block>
        <router-link to="/">返回</router-link>
      </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { apiGetManageMachineList } from '@/request/apis/Stock/StockApi'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'StockList',
  setup () {
    const store = useStore()
    const router = useRouter()
    const data = reactive({
      userId: store.state.user.user_id,
      list: []
    })

    onMounted(() => {
      apiGetManageMachineList(data.userId).then((res) => {
        data.list = res.data.data
      })
    })
    const toRouter = item => (router.push({ name: 'StockMachineDetail', params: { item } }))

    return { data, toRouter }
  }
})

</script>

<style scoped lang="scss">
@import "../../style/global";

.container {
  height: 100vh;
  text-align: center;

  .wrap {
    display: flex;
    flex-direction: column;
    height: calc(100% - 1.3rem);
    align-items: center;
    box-shadow: 0 2px 9px 1px rgb(131 131 131 / 40%);

    .list {
      display: inline-block;
      flex-shrink: 1;
      flex-grow: 1;
      overflow-y: auto;
      width: 100%;
      height: 16rem;
      //height: calc(100% - 100px); // TAG 未能生效
      font-size: .35rem;
      background-color: #fff;
      border-radius: .2rem;
    }
  }

  .footer{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 0.25rem;

    .btn {
      height: 1.3rem;
    }
  }
}

.list-item {
  height: 1.2rem;
  line-height: 1.2rem;
  padding: 0 .2rem;
  border-bottom: 1px solid #f0f0f0;
}

.list-item:hover {
  border-radius: .2rem;
  background: #f0f0f0;
}

.btn {
  border: none;
  color: #7f7e7e;
  font-size: 0.45rem;
  background-color: #D1D1D1;
  box-shadow: 0 2px 9px 1px rgb(131 131 131 / 40%);
  @include themeFontFamily();
}
</style>
